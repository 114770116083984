<template>
  <v-container
      id="projects"
      fluid
      align="center"
  >

    <base-material-card
        icon="mdi-plus"
        inline
        :title="`Create ${label} Project`"
    >
      <template v-if="loading">
        <v-progress-linear
            indeterminate
            color="primary"
        ></v-progress-linear>
        <v-skeleton-loader loading class="mx-auto" type="card"></v-skeleton-loader>
      </template>
      <v-card-text v-else>
        <div v-if="canAccess({module: 'saas', method: 'get', route: '/project'})" class="text-right">
          <v-btn small color="warning lighten-2"
                 @click="$router.push({name: type === 'saas' ? 'saaSProjects': 'enterpriseProjects' })">
            <v-icon class="mr-2">mdi-arrow-left-circle</v-icon>
            Back
          </v-btn>
        </div>
        <v-container>
          <form @submit="checkForm" novalidate="true">
            <v-row>
              <v-col xs="12" sm="12" md="12" lg="6" xl="6">

                <v-text-field
                    prepend-icon="mdi-barcode"
                    color="secondary"
                    label="Project Name..."
                    v-model="project.name"
                />

                <v-text-field
                    prepend-icon="mdi-text-short"
                    color="secondary"
                    label="Description..."
                    v-model="project.description"
                />

                <v-select
                    :items="paymentMethods"
                    label="Payment Method"
                    prepend-icon="mdi-credit-card"
                    v-model="project.paymentMethod"
                    item-text="name"
                    item-value="id"
                >
                  <template v-slot:selection="{ item }">
                    {{ item.card.brand }} - {{ item.card.last4 }} ({{ item.card.exp_month }}/{{ item.card.exp_year }})
                  </template>

                  <template v-slot:item="{item}">
                    {{ item.card.brand }} - {{ item.card.last4 }} ({{ item.card.exp_month }}/{{ item.card.exp_year }})
                  </template>

                  <template v-slot:no-data>
                    <p class="py-2 px-3">No Payment Methods Found! Please add a Credit Card to proceed,
                      <router-link :to="{'name': 'addPayment'}">Click Here</router-link>
                      .
                    </p>
                  </template>
                </v-select>

                <v-select
                    :items="productTypes"
                    @change="changeType()"
                    :label="type ==='saas' ? 'Product Size' : 'License Type'"
                    prepend-icon="mdi-layers"
                    v-model="project.productType"
                    item-text="description"
                    item-value="id"
                >
                  <template v-slot:selection="{ item }">
                    {{ item.productInfo.name }}
                  </template>

                  <template v-slot:item="{item}">
                    {{ item.productInfo.name }}
                  </template>
                </v-select>
              </v-col>

              <v-col xs="12" sm="12" md="12" lg="6" xl="6" v-if="projectProductType">

                <v-simple-table dense v-if="type === 'saas'">
                  <template v-if="projectProductType.productInfo" v-slot:default>
                    <tbody>
                    <tr>
                      <td colspan="2" class="text-center mb-6" :style="{'border-bottom':'none'}">
                        <h3>{{ projectProductType.productInfo.name }}</h3>
                        <p class="text-muted" v-if="projectProductType.productInfo.description">
                          {{ projectProductType.productInfo.description }}</p>
                      </td>
                    </tr>
                    <tr>
                      <td class="text-left secondary white--text font-weight-bold" width="30%">
                        vCPU
                      </td>
                      <td class="text-right">{{ projectProductType.productInfo.metadata.vCPU }}</td>
                    </tr>
                    <tr>
                      <td class="text-left secondary white--text font-weight-bold">
                        RAM
                      </td>
                      <td class="text-right">{{ projectProductType.productInfo.metadata.RAM }}</td>
                    </tr>
                    <tr>
                      <td class="text-left secondary white--text font-weight-bold">
                        Storage
                      </td>
                      <td class="text-right">{{ projectProductType.productInfo.metadata.Storage }}</td>
                    </tr>
                    <tr>
                      <td class="text-left secondary white--text font-weight-bold">
                        IOPS
                      </td>
                      <td class="text-right">{{ projectProductType.productInfo.metadata.IOPS }}</td>
                    </tr>
                    <tr>
                      <td class="text-left secondary white--text font-weight-bold">
                        Connections
                      </td>
                      <td class="text-right" colspan="2">
                        {{ projectProductType.productInfo.metadata.connections }}
                      </td>
                    </tr>
                    <tr v-if="projectProductType.productInfo.trialDays">
                      <td class="text-left error white--text font-weight-bold">
                        Free trial
                      </td>
                      <td class="text-right text-uppercase">
                        {{ projectProductType.productInfo.trialDays }} Days
                      </td>
                    </tr>
                    <tr>
                      <td class="text-left error white--text font-weight-bold">
                        Price&nbsp;/&nbsp;{{ projectProductType.recurring.interval }}
                      </td>
                      <td class="text-right text-uppercase">
                        <v-chip label color="error" class="mt-1 mb-1">
                          <vue-numeric
                              class="d-inline"
                              output_type="string"
                              currency="$"
                              separator=","
                              v-bind:precision="2"
                              :read-only="true"
                              v-model="projectProductType.display_unit_amount">
                          </vue-numeric>
                        </v-chip>
                      </td>
                    </tr>

                    </tbody>
                  </template>
                </v-simple-table>
                <template v-if="type === 'enterprise'">
                  <v-card
                      flat
                      class="pt-1"
                  >
                    <v-card-title class="mb-3 accent--text">
                      <v-icon class="mr-2 accent--text">mdi-server</v-icon>
                      {{ projectProductType.name }}
                    </v-card-title>
                    <v-card-text>
                      {{ projectProductType.description }}
                    </v-card-text>
                    <v-simple-table class="pl-2 pb-4">
                      <template v-slot:default>
                        <tbody>
                        <tr>
                          <td class="text-left error--text font-weight-bold">
                            Price&nbsp;/&nbsp;{{ projectProductType.recurring.interval }}
                          </td>
                          <td class="text-right text-uppercase">
                            <v-chip label color="error" class="mt-1 mb-1">
                              <vue-numeric
                                  class="d-inline"
                                  output_type="string"
                                  currency="$"
                                  separator=","
                                  v-bind:precision="2"
                                  :read-only="true"
                                  v-model="projectProductType.display_unit_amount">
                              </vue-numeric>
                            </v-chip>
                          </td>
                        </tr>
                        <tr v-if="projectProductType.productInfo.trialDays">
                          <td class="text-left error--text font-weight-bold">
                            Free Trial
                          </td>
                          <td class="text-right text-uppercase">
                            <v-chip label color="error" class="mt-1 mb-1">
                              {{ projectProductType.productInfo.trialDays }} Days
                            </v-chip>
                          </td>
                        </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card>
                </template>

              </v-col>
              <v-col xs="12" sm="12" md="12" lg="6" xl="6" v-else>
                <template v-if="type ==='enterprise'">
                  <v-hover
                      v-slot="{ hover }"
                      open-delay="50"
                  >
                    <v-card
                        elevation="2"
                        class="pt-1"
                        :color="hover ? 'accent': ''"
                    >
                      <v-card-title class="mb-3" :class="{'accent--text': !hover}">
                        <v-icon class="mr-2" :class="{'accent--text': !hover}">mdi-server</v-icon>
                        M360API &reg; Enterprise
                      </v-card-title>
                      <v-card-text :class="(hover) ? 'white--text': ''">
                        For larger enterprises that want an unlimited multi-cloud or on-premise solution.
                      </v-card-text>
                      <v-list
                          id="enterpriseFeatures"
                          :flat="true"
                          :tile="true"
                      >
                        <v-subheader>Features</v-subheader>
                        <v-list-item-group>
                          <v-list-item
                              v-for="(item, i) in enterpriseFeatures"
                              :key="i"
                          >
                            <v-list-item-icon>
                              <v-icon :class="(hover) ? '': 'accent--text'" v-text="item.icon"></v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title :class="(hover) ? 'white--text': ''"
                                                 v-text="item.text"></v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                    </v-card>
                  </v-hover>
                </template>
                <template v-else>
                  <v-hover
                      v-slot="{ hover }"
                      open-delay="50"
                  >
                    <v-card
                        elevation="2"
                        class="pt-1"
                        :color="hover ? 'secondary': ''"
                    >
                      <v-card-title class="mb-3" :class="{'secondary--text': !hover}">
                        <v-icon class="mr-2" :class="{'secondary--text': !hover}">mdi-cloud</v-icon>
                        M360API &reg; SAAS
                      </v-card-title>
                      <v-card-text :class="(hover) ? 'white--text': ''">
                        Get Started building your project and select a package that fits your needs.
                      </v-card-text>
                      <v-list
                          id="saasFeatures"
                          :flat="true"
                          :tile="true"
                      >
                        <v-subheader>Features</v-subheader>
                        <v-list-item-group>
                          <v-list-item
                              v-for="(item, i) in saasFeatures"
                              :key="i"
                          >
                            <v-list-item-icon>
                              <v-icon :class="(hover) ? '': 'secondary--text'" v-text="item.icon"></v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title :class="(hover) ? 'white--text': ''"
                                                 v-text="item.text"></v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                    </v-card>
                  </v-hover>
                </template>
              </v-col>
            </v-row>
          </form>
        </v-container>
      </v-card-text>
      <v-card-actions v-if="!loading">
        <v-spacer></v-spacer>
        <v-btn
            v-if="canAccess({module: 'saas', method: 'post', route: '/project/:type'})"
            right
            color="secondary"
            @click="checkForm"
        >
          <v-icon class="mr-1">mdi-content-save</v-icon>
          Save
        </v-btn>
        <v-btn
            right
            color="error"
            @click="resetForm"
        >
          <v-icon class="mr-1">mdi-cancel</v-icon>
          Reset
        </v-btn>
      </v-card-actions>
    </base-material-card>
  </v-container>
</template>

<script>

import globalMixins from "../../../../mixins/globalMixins";
import VueNumeric from "vue-numeric";

const data = {
  loading: true,
  paymentMethods: [],
  productTypes: [],
  projectProductType: null,
  project: {
    name: '',
    description: '',
    paymentMethod: '',
    productType: ''
  }
};

const methods = {

  async initialize() {
    this.projectProductType = null;
    this.loading = true;
    this.resetForm();
    if (this.type === 'enterprise') {
      await this.checkProjects();
    }
    setTimeout(async () => {
      await this.getData();
    }, 500);
  },

  async checkProjects() {
    const self = this;

    self.getSendData({
      noLoading: true,
      url: '/saas/project',
      method: 'get',
      params: {
        "type": 'enterprise'
      }
    }).then(response => {
      if (response.length) {
        // GOTO listing
        self.$router.push({name: 'enterpriseProjects'});
      }
    })
  },

  async getData() {
    const self = this;

    async function getPaymentMethod() {

      await self.getSendData({
        url: '/saas/paymentMethod',
        method: 'get',
        params: {}
      }).then(response => {
        self.paymentMethods = response.rows;
        getProductPrice();
      }).catch(error => {
        // this.handleErrorResponse(error);
      });
    }

    async function getProductPrice() {
      await self.getSendData({
        url: '/saas/productPrice',
        method: 'get',
        params: {
          'type': self.type
        }
      }).then(response => {
        self.productTypes = response.rows;
        self.productTypes.forEach((oneProductType) => {
          oneProductType.display_unit_amount = oneProductType.unit_amount / 100;
        });
      }).catch(error => {
        //this.handleErrorResponse(error);
      });
    }

    await getPaymentMethod();

    self.loading = false;

  },

  changeType() {
    const self = this;
    this.productTypes.forEach((oneProductType) => {
      if (oneProductType.id === self.project.productType) {
        self.projectProductType = {...oneProductType};
      }
    });
  },

  checkForm() {
    const self = this;
    if (!this.project.name || this.project.name.trim() === '') {
      self.pushMessage({
        type: 'error',
        title: 'Create Project Error',
        text: 'Please enter the name of the project!'
      });
      return false;
    }

    if (!this.project.description || this.project.description.trim() === '') {
      self.pushMessage({
        type: 'error',
        title: 'Create Project Error',
        text: 'Please enter the project description!'
      });
      return false;
    }

    if (!this.project.paymentMethod) {
      self.pushMessage({
        type: 'error',
        title: 'Create Project Error',
        text: 'Please select a payment method to proceed!'
      });
      return false;
    }

    if (!this.project.productType) {
      self.pushMessage({
        type: 'error',
        title: 'Create Project Error',
        text: 'Please select the size of the product you want to create!'
      });
      return false;
    }

    this.createProject();
  },

  resetForm() {
    this.project = {
      name: '',
      description: '',
      paymentMethod: '',
      productType: ''
    };
  },

  createProject() {
    const self = this;
    const options = {
      url: `/saas/project/${self.type}`,
      method: 'post',
      params: {
        "project": {
          name: self.project.name,
          description: self.project.description
        },
        "subscription": {
          paymentMethod: self.project.paymentMethod,
          priceId: self.project.productType
        }
      }
    };

    self.getSendData(options)
        .then(response => {
          self.pushMessage({
            type: 'success',
            title: 'Project Created',
            text: `Project ${self.project.name} has been created. It may take a few minutes for the project to become ready.`
          });
          self.resetForm();
          this.scrollToTop();
          setTimeout(() => {
            self.$router.push({name: self.type === 'saas' ? 'saaSProjects' : 'enterpriseProjects'});
          }, 3000);
        }).catch(error => {
      //this.handleErrorResponse(error);
    });
  }
};

export default {
  name: 'createProject',
  methods: methods,
  props: {
    type: {
      type: String,
      validator: function (value) {
        return ['saas', 'enterprise'].includes(value);
      }
    },
    enterpriseFeatures: {
      type: Array
    },
    saasFeatures: {
      type: Array
    }
  },
  components: {
    VueNumeric
  },

  computed: {
    label: function () {
      return (this.type === 'saas') ? 'SAAS' : 'Enterprise';
    }
  },

  mixins: [globalMixins],

  async created() {
    this.clearMessages();
    setTimeout(() => {
      this.initialize();
    }, 500);
  },

  data: () => (data),
}
</script>
